import React, { useContext, useState, useEffect } from "react";

import RoodieAPI from "../../../lib/api";
import { useStore } from "../../hooks/store";
import AuthContext from "../../hooks/auth-context";

import { Skeleton } from "antd";

const BookTextView = (props) => {
  // get store content
  var store = useStore()[0];
  // prepare auth context
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  var [loading, setLoading] = useState(true);
  var [selectedPage, setSelectedPage] = useState("");

  // fetch page content
  useEffect(() => {
    setLoading(true);
    if (store.state[0].activebook == null) {
    } else {
      RoodieAPI.getPage(
        store.state[0].activebook,
        store.state[0].bookstate.activepage,
        token
      )
        .then((page) => {
          setSelectedPage(page.content);
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [setSelectedPage, store, token]);

  return (
    <div className="bookContent">
      <Skeleton loading={loading}>
        <div dangerouslySetInnerHTML={{ __html: selectedPage }}></div>
        <div className="bookContentPageNumber">
          {store.state[0].bookstate.activepage}/
          {store.state[0].bookstate.maxpages}
        </div>
      </Skeleton>
    </div>
  );
};

export default BookTextView;
