import React, { useState, useContext } from "react";

import { useStore } from "../../hooks/store";

import { Card, Badge, Tag, Divider } from "antd";

const { Meta } = Card;

const BookListView = (props) => {
  const books = props.books;

  var [store, dispatch] = useStore();

  // rerender after book was selected
  var [activeBook, setActiveBook] = useState("");

  const handleBookSelect = (id, pages) => {
    if (id === store.state[0].activebook) {
      dispatch("BOOK_ACTIVE", null);
      setActiveBook("");
    } else {
      dispatch("BOOK_ACTIVE", id);
      dispatch("BOOK_MAX_PAGES", pages);
      setActiveBook(id);
    }
  };

  // get three elements each for the carousel
  // get placeholder image if there is no link to image https://via.placeholder.com/240x180

  return (
    <div className="bookList">
      {books.map((book, key) => (
        <div className="libaryBook" key={key}>
          <Badge.Ribbon color="cyan" text={book.age}>
            <Card
              hoverable
              onClick={() => handleBookSelect(book.id, book.pages)}
              size="small"
              cover={
                <img
                  alt="example"
                  src={process.env.REACT_APP_ROODIE_ASSETS + book.cover}
                />
              }
              className={activeBook === book.id ? "bookSelected" : "not"}
            >
              <Meta
                title={book.name}
                description="text to be added ... blablab"
              />
              <Divider style={{ marginBottom: 10 }} />
              <div>pages {book.pages}</div>
              {book.tags &&
                book.tags.map((tags, key) => (
                  <Tag key={key} color="blue">
                    {tags}
                  </Tag>
                ))}
            </Card>
          </Badge.Ribbon>
        </div>
      ))}
    </div>
  );
};

export default BookListView;
