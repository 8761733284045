import React, { useState } from "react";

import { Button, Input, Col, Row, message, Space } from "antd";
import { useNavigate } from "react-router-dom";

import RoodieAPI from "../../lib/api";
import { symbolsTable, symbolsToCode } from "../../lib/sessionCodes";
import { useStore } from "../hooks/store";

const ChildLandingPage = () => {
  // icon code generator
  const [icons, setIcons] = useState("");
  const [numbersCode, setNumbersCode] = useState("");

  var dispatch = useStore()[1];

  // navigate
  const navigate = useNavigate();
  const navigateSession = () => {
    navigate("/childsession");
  };
  const navigateStart = () => {
    navigate("/");
  };

  // mock session init and navigate after 2 seconds
  const initializeSession = () => {
    message.success("Starting session!");
    setTimeout(() => {
      navigateSession();
    }, 2000);
  };

  // navigate to startpage when ws connection was closed
  const sessionClosedThroughWS = () => {
    message.warning("Websocket connection closed, please start a new session!");
    setTimeout(() => {
      navigateStart();
    }, 2000);
  };

  const codeIconInput = (e) => {
    setIcons(icons + e.currentTarget.value);
  };

  const deleteLastInput = () => {
    // delete last icon
    setIcons([...icons].slice(0, -1).join(""));
  };

  const connect = () => {
    var code = null;
    // only for testing purposes check if there is a code or numbers and select
    if (icons === "") {
      console.log("no icons");
      code = numbersCode;
    } else {
      code = symbolsToCode(icons);
    }

    RoodieAPI.joinSession(code)
      .then((session) => {
        let url = RoodieAPI.getSignalUrl(session);
        dispatch("VAUTH_ACTIVE", session.vauth);
        // set session type to kid
        dispatch("SESSION_KID");

        if (window["WebSocket"]) {
          const ws = new WebSocket(url);
          dispatch("ESTABLISH_CONNECTION", ws);

          initializeSession();

          ws.onclose = function (evt) {
            console.log("connection closed");
            sessionClosedThroughWS();
          };
          ws.onmessage = function (evt) {
            var obj = JSON.parse(evt.data);
            dispatch("STATE_UPDATE", obj, true);
          };
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  // get buttons in two rows
  const buttonRows = symbolsTable.reduce((rows, key, index) => {
    return (
      (index % 5 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
      rows
    );
  }, []);

  return (
    <div className="contentMargin">
      <h1>Set up connection </h1>
      <p>
        By clicking the icons you can insert the session code you got from the
        person reading with you.
      </p>

      <Space direction="vertical">
        {buttonRows.map((symbol, index) => {
          return (
            <Space key={Math.random()} align="center" size="middle">
              <Button
                onClick={codeIconInput}
                size="large"
                shape="circle"
                key={Math.random()}
                value={symbol[0]}
              >
                {symbol[0]}
              </Button>
              <Button
                onClick={codeIconInput}
                size="large"
                shape="circle"
                key={Math.random()}
                value={symbol[1]}
              >
                {symbol[1]}
              </Button>
              <Button
                onClick={codeIconInput}
                size="large"
                shape="circle"
                key={Math.random()}
                value={symbol[2]}
              >
                {symbol[2]}
              </Button>
              <Button
                onClick={codeIconInput}
                size="large"
                shape="circle"
                key={Math.random()}
                value={symbol[3]}
              >
                {symbol[3]}
              </Button>
              <Button
                onClick={codeIconInput}
                size="large"
                shape="circle"
                key={Math.random()}
                value={symbol[4]}
              >
                {symbol[4]}
              </Button>
            </Space>
          );
        })}
      </Space>

      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <Col span={8}>
          <Input showCount maxLength={6} id="icons" value={icons}></Input>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Button onClick={deleteLastInput}>Delete last input!</Button>
        </Col>
        <Col span={6}>
          <Button onClick={connect} type="primary">
            Connect!
          </Button>
        </Col>
      </Row>

      <div style={{ marginTop: 150 }}>Only for testing - direct code copy</div>
      <Input
        style={{ width: 200 }}
        showCount
        maxLength={6}
        onChange={(e) => setNumbersCode(e.target.value)}
      ></Input>
    </div>
  );
};

export default ChildLandingPage;
