const symbolsTable = [
    "🐮",
    "🐶",
    "🐷",
    "🐵",
    "🐭",
    "🐴",
    "🐥",
    "🐟",
    "🐌",
    "🐸"
]

const symbolToNumber = (symbol) => {
    return symbolsTable.indexOf(symbol).toString()
}

const numberToSymbol = (number) => {
    return symbolsTable[parseInt(number)]
}

const codeToSymbols = (code) => {
    code = code.trim()
    let symbols = ""
    for (let i = 0; i < code.length; i++) {
        symbols += numberToSymbol(code[i])
    }
    return symbols
}

const symbolsToCode = (symbols) => {
    symbols = symbols.trim()
    let code = ""
    for (const ch of symbols) {
        code += symbolToNumber(ch)
    }
    return code
}


export {symbolsTable, codeToSymbols, symbolsToCode}
