import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    BOOK_ACTIVE: (curState, bookId) => {
      curState.state[0].activebook = bookId;
      // reset active page to 1 when selecting a new book
      curState.state[0].bookstate.activepage = 1;
    },
    BOOK_MAX_PAGES: (curState, maxPages) => {
      curState.state[0].bookstate.maxpages = maxPages;
    },
    RESET_BOOKSTATE: (curState) => {
      let state = {
        activebook: null,
        connectionId: null,
        bookstate: {
          activepage: 1,
          activeanimations: [],
          maxpages: 1,
          scenelink: null,
        },
      };
      curState.state[0] = state;
      // if websocket connection ended inbetween the button for closing the session won't work anymore
      curState.websocket.send(JSON.stringify(state));
    },
    SET_SCENE_LINK: (curState, scene) => {
      curState.state[0].bookstate.scenelink = scene;
    },
    STATE_PREV_PAGE: (curState, sceneLink) => {
      var newState = JSON.parse(JSON.stringify(curState));
      newState.state[0].bookstate.scenelink = sceneLink;
      newState.state[0].bookstate.activepage =
        newState.state[0].bookstate.activepage - 1;
      curState.websocket.send(JSON.stringify(newState.state[0]));
    },
    STATE_NEXT_PAGE: (curState, sceneLink) => {
      if (
        curState.state[0].bookstate.activepage ===
        curState.state[0].bookstate.maxpages
      ) {
        console.log("no more pages");
      } else {
        var newState = JSON.parse(JSON.stringify(curState));
        newState.state[0].bookstate.scenelink = sceneLink;
        newState.state[0].bookstate.activepage =
          newState.state[0].bookstate.activepage + 1;
        curState.websocket.send(JSON.stringify(newState.state[0]));
      }
    },
    VAUTH_ACTIVE: (curState, vauth) => {
      curState.vauth = vauth;
    },
    CONNECTIONID_ACTIVE: (curState, connectionId) => {
      curState.state[0].connectionId = connectionId;
    },
    SET_ANIMATION: (curState, animation) => {
      var animationsArray = curState.state[0].bookstate.activeanimations;
      // replace if already there
      // todo.. handling of more animations
      var index = animationsArray.indexOf(animation);
      if (~index) {
        animationsArray[index] = animation;
      } else {
        animationsArray.push(animation);
      }
    },
    STATE_UPDATE: (curState, message) => {
      console.log(
        "update state triggered from WS connection",
        message,
        message.hasOwnProperty("activebook")
      );
      // check if activebook is in array if not we want to send out the current state as the message
      // that was send out was a request for the current status, send the current status then in the websocket
      // and check if the state was changed compared what is already written to the local state... if not
      // do not send a statechange message over the websocket -> todo... not really working yet
      if (
        message.hasOwnProperty("activebook") &&
        curState.state[0] !== message
      ) {
        curState.state[0] = message;
      } else if (message.hasOwnProperty("staterequest")) {
        console.log("Staterequest was send");
        // only send if current book is not null
        if (curState.state[0].activebook !== null)
          curState.websocket.send(JSON.stringify(curState.state[0]));
      } else {
        console.log("staterequest without trigger");
      }
    },
    ESTABLISH_CONNECTION: (curState, connection) => {
      curState.websocket = connection;
    },
    REQUEST_STATUS: (curState) => {
      curState.websocket.send(JSON.stringify({ staterequest: true }));
    },
    SESSION_READER: (curState) => {
      curState.sessiontype = "reader";
    },
    SESSION_KID: (curState) => {
      curState.sessiontype = "kid";
    },
  };
  initStore(actions, {
    sessiontype: null,
    websocket: null,
    vauth: null,
    state: [
      {
        activebook: null,
        connectionId: null,
        bookstate: {
          activepage: 1,
          activeanimations: [],
          maxpages: 1,
          scenelink: null,
        },
      },
    ],
  });
};

export default configureStore;
