import React from "react";
import { Route, Routes } from "react-router-dom";

import * as THREE from "three";

// using layout to encapsulate the header to only the components we want to have it in
import Layout from "./components/views/components/layout";

import LandingPage from "./components/views/landingPage";
import ReaderLandingPage from "./components/views/readerLandingPage";
import ChildLandingPage from "./components/views/childLandingPage";

import ReaderSessionView from "./components/views/session/readerSession";
import ChildSessionView from "./components/views/session/childSession";

import "antd/dist/antd.min.css";
import "./App.css";
import AuthForm from "./components/auth/AuthForm";

const App = () => {
  // init renderer to pass it to animation pages
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setClearColor(0xcccccc);

  return (
    <div className="background">
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/auth" element={<AuthForm />} />
          <Route exact path="/reader" element={<ReaderLandingPage />} />
          <Route exact path="/child" element={<ChildLandingPage />} />
        </Route>
        <Route
          exact
          path="/readersession"
          element={<ReaderSessionView renderer={renderer} />}
        />
        <Route
          exact
          path="/childsession"
          element={<ChildSessionView renderer={renderer} />}
        />
      </Routes>
    </div>
  );
};

export default App;
