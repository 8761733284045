import React, { useState, useContext, useEffect } from "react";

import { Select, Descriptions, message } from "antd";

import BookListView from "../components/bookListView";

import AuthContext from "../../hooks/auth-context";
import RoodieAPI from "../../../lib/api";

const { Option } = Select;

const BookSelectView = () => {
  // filtering and loading of books
  const agegroups = [
    "all age groups",
    "1 year",
    "2-3 years",
    "4-5 years",
    "6 years+",
  ];

  const [ageselected, setAgeselected] = useState("non selected");
  const [filteredData, setFilteredData] = useState([]);
  const [books, setBooks] = useState([]);

  // load books
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  // load books from api
  useEffect(() => {
    RoodieAPI.listBooks(token)
      .then((books) => {
        setBooks(books);
        // initial state show all books
        setFilteredData(books);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        message.error("Authentication error (log out and in again)", error);
      });
  }, [setBooks, setFilteredData, token]);

  // filter books according to dropdown value
  const handleFilterChange = (value) => {
    setAgeselected(value);
    // default full books array
    if (value === "all age groups") {
      setFilteredData(books);
    } else {
      setFilteredData(books.filter((item) => item.age === value));
    }
  };

  return (
    <React.Fragment>
      <Descriptions>
        <Descriptions.Item label="Filter for age">
          <Select
            defaultValue="all age groups"
            style={{ width: 120 }}
            onChange={handleFilterChange}
          >
            {agegroups.map((group, key) => (
              <Option key={key} value={group}>
                {group}
              </Option>
            ))}
          </Select>
        </Descriptions.Item>
      </Descriptions>
      <BookListView books={filteredData} />
    </React.Fragment>
  );
};

export default BookSelectView;
