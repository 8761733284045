import React, { useContext, useEffect, useState } from "react";

import { useStore } from "../../hooks/store";
import { Spin } from "antd";

import AuthContext from "../../hooks/auth-context";
import RoodieAPI from "../../../lib/api";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const AnimationView = (props) => {
  var [store, dispatch] = useStore();
  var [loading, setLoading] = useState(false);
  // class for animationview -> changes depending if the child or the parent is opening the animation view
  var [contentClass, setContentClass] = useState("animationContent");

  // prepare auth context
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  // init stuff
  var camera, animations;
  var container = undefined;
  var renderer = props.renderer;
  var mixer,
    model = undefined;
  var scene = new THREE.Scene();
  var clock = new THREE.Clock();

  // setup scene

  /// mouse interaction and click stuff
  var raycaster = new THREE.Raycaster();
  var mouseVector = new THREE.Vector3();

  // get mouse clicks on elements in rendered scene
  function onMouseMove(e) {
    const rect = renderer.domElement.getBoundingClientRect();
    mouseVector.x =
      ((e.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
    mouseVector.y =
      -((e.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

    raycaster.setFromCamera(mouseVector, camera);
    if (model !== undefined) {
      var intersects = raycaster.intersectObjects(model.children, true);
      for (var i = 0; i < intersects.length; i++) {
        var intersection = intersects[i],
          obj = intersection.object;
        // handle logic to start animation
        // check if clicked object has storytimeaction property
        if ("storytimeaction" in obj.userData) {
          // ad aminmation replay to bookstate
          dispatch("SET_ANIMATION", obj.userData);

          for (var animation in animations) {
            var searchString = "storytime";
            searchString += obj.userData.storytimeaction;
            if (animations[animation].name.includes(searchString)) {
              var actionClick = mixer.clipAction(animations[[animation]]);
              // TODO: reset Animation and PLAY again
              // TODO: reset animation in store after playing once
              actionClick.setLoop(THREE.LoopOnce);
              actionClick.play();
            }
          }
        }
      }
    } else {
      console.log("model not loaded yet");
    }
  }

  function animateBasic() {
    requestAnimationFrame(animateBasic);
    var delta = clock.getDelta();
    if (mixer != null) {
      mixer.update(delta);
    }
    renderer.render(scene, camera);
  }

  function initScene(htmlElement, filePath) {
    container = htmlElement;
    scene.background = new THREE.Color(0xffffff);
    camera = new THREE.PerspectiveCamera(
      50,
      container.clientWidth / container.clientHeight,
      0.01,
      100
    );
    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.2);
    camera.add(directionalLight);
    scene.add(ambientLight);
    scene.add(camera);

    renderer.setSize(container.clientWidth, container.clientHeight);

    // disabled controls
    //const controls = new OrbitControls(camera, renderer.domElement);
    //controls.screenSpacePanning = false;
    //controls.maxPolarAngle = Math.PI / 2;

    var loader = new GLTFLoader();
    loader.load(
      filePath,
      (gltf) => {
        // called when the resource is loaded
        console.log("finished loading model", gltf);
        setLoading(false);
        model = gltf.scene;
        // not sure if needed
        model.traverse(function (obj) {
          obj.frustumCulled = false;
        });

        camera.position.copy(gltf.cameras[0].parent.position);
        camera.quaternion.copy(gltf.cameras[0].parent.quaternion);
        camera.scale.copy(gltf.cameras[0].parent.scale);
        camera.lookAt(new THREE.Vector3(0, 0, 0));
        camera.fov = gltf.cameras[0].fov;

        scene.add(model);
        camera.updateProjectionMatrix();

        // init animation mixer and load animations from scene
        mixer = new THREE.AnimationMixer(model);
        animations = gltf.animations;
        // check for basicactions to run instantly
        for (var animation in animations) {
          if (animations[animation].name.includes("basicaction")) {
            var action = mixer.clipAction(animations[[animation]]);
            action.play();
          }
        }

        // click event to handle animations;
        window.addEventListener("click", onMouseMove, false);

        animateBasic();
      },
      (xhr) => {
        //console.log("xhr load", xhr);
        // called while loading is progressing
        // problem: expected size not passed... so we can not use it as a progress timer
        // console.log("The xhr warning isL ",xhr.srcElement.responseText);
      }
    );

    container.innerHTML = "";
    container.appendChild(renderer.domElement);

    window.addEventListener("resize", onWindowResize, false);
  }

  function onWindowResize() {
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(container.clientWidth, container.clientHeight);
  }

  useEffect(() => {
    // we have an extra state that is set wether the system is logged in as a reader or a kid
    // we are transfering the page.scene from the callback but not execute the API call when we are on the child side of things
    if (store.sessiontype === "kid") {
      // handle kid animation stuff... if kid is logged in the page.scene should be fetched via the bookstate.animationlink

      // content class will show a full screen animation
      setContentClass("animationContent_child");
      if (store.state[0].activebook !== null) {
        setLoading(true);
        // get filepath from state and init animation with link
        initScene(
          document.getElementById("container"),
          store.state[0].bookstate.scenelink
        );
        onWindowResize();
      }
    } else if (store.sessiontype === "reader") {
      if (store.state[0].activebook == null) {
        console.log("no book selected for animation");
      } else {
        // reload on new selected page
        //handle initial loading of new page...
        if (store.state[0].bookstate.scenelink === null) {
          RoodieAPI.getPage(
            store.state[0].activebook,
            store.state[0].bookstate.activepage,
            token
          )
            .then((page) => {
              const sceneLink =
                process.env.REACT_APP_ROODIE_ASSETS + page.scene;
              setLoading(true);
              store.state[0].bookstate.scenelink = sceneLink;
              initScene(document.getElementById("container"), sceneLink);
              onWindowResize();
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        } else {
          setLoading(true);
          initScene(
            document.getElementById("container"),
            store.state[0].bookstate.scenelink
          );
          onWindowResize();
        }
      }
    }
  }, [store]);

  return (
    <div>
      <Spin
        className="loadingAnimation"
        spinning={loading}
        tip=""
        size="large"
      ></Spin>
      <div
        className={contentClass}
        style={{ height: "100%", width: "100%" }}
        id="container"
      ></div>
    </div>
  );
};

export default AnimationView;
