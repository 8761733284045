import React, { useState, useContext, useEffect } from "react";

import { codeToSymbols } from "../../lib/sessionCodes";
import RoodieAPI from "../../lib/api";
import AuthContext from "../hooks/auth-context";
import { useNavigate } from "react-router-dom";
import { useStore } from "../hooks/store";

import { message, Col, Row, Typography, Button } from "antd";

const ReaderLandingPage = () => {
  // initialisation of navigation to reader session
  const navigate = useNavigate();
  const navigateSession = () => {
    navigate("/readersession");
  };
  const navigateStart = () => {
    navigate("/");
  };

  const { Title, Paragraph } = Typography;

  // prepare auth context
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  var [store, dispatch] = useStore();
  var [sessionCode, setSessionCode] = useState("");
  var [symbolsCode, setSymbolsCode] = useState("");

  // navigate to startpage when ws connection was closed
  const sessionClosedThroughWS = () => {
    message.warning("Websocket connection closed, please start a new session!");
    setTimeout(() => {
      navigateStart();
    }, 2000);
  };

  // start session
  useEffect(() => {
    RoodieAPI.newSession(token)
      .then((session) => {
        setSessionCode(session.code);
        setSymbolsCode(codeToSymbols(session.code));
        // save session information into the overall state
        dispatch("CONNECTIONID_ACTIVE", session.id);
        dispatch("VAUTH_ACTIVE", session.vauth);
        dispatch("SESSION_READER");

        let url = RoodieAPI.getSignalUrl(session);

        if (window["WebSocket"]) {
          const ws = new WebSocket(url);
          dispatch("ESTABLISH_CONNECTION", ws);

          ws.onclose = function (evt) {
            console.log("connection closed", evt);
            sessionClosedThroughWS();
          };
          ws.onmessage = function (evt) {
            var obj = JSON.parse(evt.data);
            dispatch("STATE_UPDATE", obj, true);
          };
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
        message.error("Error", error);
      });
  }, [store.state, token]);

  // mock session init and navigate after 5 seconds
  const initializeSession = () => {
    message.success("Starting session!");
    setTimeout(() => {
      navigateSession();
    }, 2000);
  };

  return (
    <div className="contentMargin">
      <Row gutter={[20, 0]} style={{ marginBottom: 20, marginTop: 40 }}>
        <Col span={12}>
          <Title level={5}>Your session code is: </Title>
          <Paragraph style={{ fontSize: 30 }} copyable>
            {sessionCode}
          </Paragraph>
          <Paragraph style={{ fontSize: 30 }} copyable>
            {symbolsCode}
          </Paragraph>
          <Paragraph>
            Share this code with the person you want a read a book to. You can
            do this either by tell them the code on the phone. Or use this
            button to write a mail with the link they can use to connect
            directly to your session
          </Paragraph>
          <Button>Invite via Mail</Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 40 }}>
        <Button type="primary" onClick={initializeSession}>
          Start session
        </Button>
      </Row>
    </div>
  );
};

export default ReaderLandingPage;
