import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useStore } from "../../hooks/store";

import AuthContext from "../../hooks/auth-context";
import RoodieAPI from "../../../lib/api";

import AnimationView from "../sessionViews/animationView";
import VideoCallView from "../sessionViews/videoCallView";
import BookTextView from "../sessionViews/bookTextView";
import BookSelectView from "./bookSelectView";

import { Button, Drawer, Space, message } from "antd";

const ReaderSessionView = (props) => {
  // get store content
  var [store, dispatch] = useStore();
  const [open, setOpen] = useState(false);

  // prepare auth context
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  // setup navigate
  const navigate = useNavigate();
  const navigateLanding = () => {
    // reset bookstate when closing the session (if the session is not stopped anyways)
    if (store.state[0].connectionId !== null) {
      dispatch("RESET_BOOKSTATE");
    }
    navigate("/reader");
  };

  // pass renderer
  const renderer = props.renderer;

  const selectNextPage = () => {
    if (
      store.state[0].bookstate.activepage === store.state[0].bookstate.maxpages
    ) {
      console.log("last page selected");
    } else {
      RoodieAPI.getPage(
        store.state[0].activebook,
        store.state[0].bookstate.activepage + 1,
        token
      )
        .then((page) => {
          const sceneLink = process.env.REACT_APP_ROODIE_ASSETS + page.scene;
          dispatch("STATE_NEXT_PAGE", sceneLink);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const selectPrevPage = () => {
    if (store.state[0].bookstate.activepage === 1) {
      console.log("first page ... no further back ");
    } else {
      RoodieAPI.getPage(
        store.state[0].activebook,
        store.state[0].bookstate.activepage - 1,
        token
      )
        .then((page) => {
          const sceneLink = process.env.REACT_APP_ROODIE_ASSETS + page.scene;
          dispatch("STATE_PREV_PAGE", sceneLink);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    // check if book was selected
    if (store.state[0].activebook !== null) {
      setOpen(false);
    } else {
      message.error("Please select a book first!");
    }
  };

  // todo -> check if book is selected... if not, show overview to select a book
  useEffect(() => {
    if (store.state[0].activebook == null) {
      setOpen(true);
    } else {
      // just for testing ... we can remove this later
      console.log("book selected", store.state[0].activebook);
    }
  }, [store]);

  return (
    <div>
      <div className="backToReaderSelection">
        <Button type="primary" onClick={showDrawer}>
          Select a book!
        </Button>
        <Button danger onClick={navigateLanding}>
          Go back to start another session
        </Button>
        <VideoCallView content="from Reader view" />
      </div>
      <Button className="nextButton" onClick={selectNextPage}>
        Next Page
      </Button>
      <Button className="prevButton" onClick={selectPrevPage}>
        Previous Page
      </Button>
      <AnimationView renderer={renderer} content="from Reader view" />
      <BookTextView />
      <Drawer
        title="Select your book"
        placement={"top"}
        closable={true}
        onClose={onClose}
        open={open}
        height={"80%"}
        extra={
          <Space>
            <Button type="primary" onClick={onClose}>
              OK
            </Button>
          </Space>
        }
      >
        <BookSelectView className="bookSelectDrawer" />
      </Drawer>
    </div>
  );
};

export default ReaderSessionView;
