import React, { useEffect } from "react";

import { useStore } from "../../hooks/store";
import { Result } from "antd";

import AnimationView from "../sessionViews/animationView";
import VideoCallView from "../sessionViews/videoCallView";

const ChildSessionView = (props) => {
  const renderer = props.renderer;

  var [store, dispatch] = useStore();

  useEffect(() => {
    console.log(
      "request selected book if no book was selected",
      store.state[0]
    );

    if (store.state[0].bookstate.scenelink === null) {
      // don't request ongoing -> timeout and 1 second wait between requests
      setTimeout(() => {
        dispatch("REQUEST_STATUS", false);
      }, 1000);
    }
  }, []);

  return (
    <div>
      <AnimationView renderer={renderer} content="from Child view" />
      <VideoCallView content="from Child view" />
      <Result
        className="noBookWarning"
        status="warning"
        title="No Book selected in this session yet. Please wait till the reader has selected a book"
      />
    </div>
  );
};

export default ChildSessionView;
