import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";
import AuthContext from "../hooks/auth-context";

import { Card, Typography } from "antd";

const LandingPage = () => {
  const authCtx = useContext(AuthContext);

  // navigate
  const navigate = useNavigate();
  const navigateChild = () => {
    navigate("/child");
  };

  const navigateReader = () => {
    navigate("/reader");
  };
  const navigateAuth = () => {
    navigate("/auth");
  };

  const getToken = () => {
    console.log(authCtx.token, authCtx);
  };

  const { Paragraph } = Typography;

  return (
    <Paragraph className="contentMargin">
      <Typography.Title level={2}>Hello</Typography.Title>
      <Paragraph className="landingPageParagraph">
        I am <b className="roddie-color">ROODIE</b>! I am an app to read
        interactive stories with your kids and grandkids
      </Paragraph>
      <Typography.Title level={3}>Use this device as a</Typography.Title>

      <div className="landingPageOptionsContainer">
        <Card
          hoverable
          key="kid"
          className="landingPageOptions"
          onClick={navigateChild}
        >
          <div className="optionHeading">Kid</div>
          <p>Listen to the stories</p>
        </Card>

        {authCtx.isLoggedIn
          ? [
              <Card
                hoverable
                key="readerLoggedIn"
                className="landingPageOptions"
                onClick={navigateReader}
              >
                <div className="optionHeading">Reader</div>
                <p>
                  Select a book, conntect to other devices and start reading a
                  story!
                </p>
              </Card>,
            ]
          : [
              <Card
                hoverable
                key="readerLoggedOut"
                className="landingPageOptions"
                onClick={navigateAuth}
              >
                <div className="optionHeading">Reader</div>
                <p>
                  Create an account or log in to start start reading a story!
                </p>
              </Card>,
            ]}
      </div>
      <button onClick={getToken}>request user token</button>
    </Paragraph>
  );
};

export default LandingPage;
