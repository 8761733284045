const RoodieAPI = {
  baseURL: process.env.REACT_APP_ROODIE_BASE_URL,
  wssBaseURL: process.env.REACT_APP_ROODIE_WSS_BASE_URL,
  livekitURL: process.env.REACT_APP_LIVEKIT_URL,
  fetch: (url, init, error) => {
    return fetch(url, init)
      .catch(() => {
        return Promise.reject({
          error: "unable to reach " + url,
          status: null,
        });
      })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject({ error: error, status: res.status });
        }
        return res;
      });
  },
  fetchJSON: (url, init, error) => {
    init.headers["Accept"] = "application/json";
    return RoodieAPI.fetch(url, init, error).then((res) => {
      return res.json();
    });
  },
  listBooks: (token) => {
    return RoodieAPI.fetchJSON(
      RoodieAPI.baseURL + "/books/",
      {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      },
      "failed to list books"
    ).then((data) => {
      return data.books;
    });
  },
  getBook: (id, token) => {
    return RoodieAPI.fetchJSON(
      RoodieAPI.baseURL + "/books/" + id,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      },
      "failed to get book " + id
    ).then((book) => {
      return book;
    });
  },
  getPage: (bookId, pageId, token) => {
    return RoodieAPI.fetchJSON(
      RoodieAPI.baseURL + "/books/" + bookId + "/pages/" + pageId,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      },
      "failed to get page " + pageId + " for book " + bookId
    ).then((page) => {
      return page;
    });
  },
  newSession: (token) => {
    return RoodieAPI.fetchJSON(
      RoodieAPI.baseURL + "/sessions/new",
      {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      },
      "failed to create new session"
    ).then((session) => {
      return session;
    });
  },
  joinSession: (code) => {
    return RoodieAPI.fetchJSON(
      RoodieAPI.baseURL + "/sessions/join/" + code,
      {
        method: "GET",
        headers: {},
      },
      "failed to join the session"
    ).then((session) => {
      return session;
    });
  },
  getSignalUrl: (session) => {
    return RoodieAPI.wssBaseURL + "/sessions/signal/" + session.id;
  },
};

export default RoodieAPI;
