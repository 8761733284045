import { useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { Button, Checkbox, Form, Input, Card, Divider } from "antd";

import AuthContext from "../hooks/auth-context";

const AuthForm = () => {
  const [form] = Form.useForm();

  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();
  const navigateLanding = () => {
    navigate("/");
  };

  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  const submitHandler = (values) => {
    const enteredEmail = values.username;
    const enteredPassword = values.password;

    // optional: Add validation

    setIsLoading(true);
    let url;
    if (isLogin) {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyARTqmZEOSevcxAkR5_p43sGsZzQn4VtEI";
    } else {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyARTqmZEOSevcxAkR5_p43sGsZzQn4VtEI";
    }
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email: enteredEmail,
        password: enteredPassword,
        returnSecureToken: true,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = "Authentication failed!";
            // if (data && data.error && data.error.message) {
            //   errorMessage = data.error.message;
            // }

            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        console.log("Auth data", data);
        authCtx.login(data.idToken);
        navigateLanding();
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Card className="contentMargin" title={isLogin ? "Login" : "Sign Up"}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={submitHandler}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="E-mail"
          name="username"
          autoComplete="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          autoComplete="current-password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 16 }}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          {!isLoading && (
            <Button type="primary" htmlType="submit">
              {isLogin ? "Login" : "Create Account"}
            </Button>
          )}
          {isLoading && <p>Sending request...</p>}
        </Form.Item>
      </Form>

      <Divider />
      <Button type="secondary" onClick={switchAuthModeHandler}>
        {isLogin ? "Create new account" : "Login with existing account"}
      </Button>
    </Card>
  );
};

export default AuthForm;
