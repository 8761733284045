import React from "react";
//import Webcam from 'react-webcam';
import { LiveKitRoom } from "@livekit/react-components";
import { useStore } from "../../hooks/store";
import RoodieAPI from "../../../lib/api";

const VideoCallView = (props) => {
  var store = useStore()[0];
  var token = store.vauth;

  // start video and audio automatically
  async function onConnected(room) {
    await room.localParticipant.setCameraEnabled(true);
    //await room.localParticipant.setMicrophoneEnabled(true);
  }

  return (
    <div className="webcam">
      <LiveKitRoom
        url={RoodieAPI.livekitURL}
        token={token}
        onConnected={(room) => onConnected(room)}
      />
    </div>
  );
};

export default VideoCallView;
