import React, { useContext } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { Layout, PageHeader, Button, Divider } from "antd";

import AuthContext from "../../hooks/auth-context";

const { Content } = Layout;

const Header = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const navigateLogin = () => {
    navigate("/auth");
  };
  const navigateLogout = () => {
    navigate("/");
  };

  const logout = () => {
    authCtx.logout();
    navigateLogout();
  };

  // get location and create header text
  const location = useLocation();

  let title = "";
  let subTitle = "";

  if (location.pathname === "/auth") {
    title = "Authentication";
    subTitle = "Please create a new user or log in with an exisitng account";
  } else if (location.pathname === "/start") {
    title = "Getting started";
    subTitle = "Select whether you want to continue as a reader or listener!";
  } else if (location.pathname === "/reader") {
    title = "Starting a session";
    subTitle = "Establish a session with the kid via the code!";
  } else if (location.pathname === "/child") {
    title = "Connect to a reader";
    subTitle = "";
  }

  return (
    <Layout className="layout">
      <Content>
        <PageHeader
          title={title}
          subTitle={subTitle}
          onBack={navigateLogout}
          backIcon={
            <img className="logo" src="roodie.png" alt="Italian Trulli" />
          }
          extra={
            authCtx.isLoggedIn
              ? [
                  <Button onClick={logout} key="1">
                    Logout
                  </Button>,
                ]
              : [
                  <Button onClick={navigateLogin} key="1" type="primary">
                    Login
                  </Button>,
                ]
          }
        />
        <Divider style={{ marginTop: "0px" }} />
      </Content>
    </Layout>
  );
};

export default Header;
